import React, { MutableRefObject, RefObject, useEffect } from 'react'

interface OverlayI {
  children: JSX.Element | boolean,
  containerRef: RefObject<HTMLDivElement>,
  isPrivate:boolean

}


function Overlay({ children, containerRef, isPrivate }: OverlayI) {

  useEffect(() => {
    if(containerRef.current && isPrivate) containerRef.current.style.overflow = "hidden";
    else if(containerRef.current) containerRef.current.style.overflow = "auto";
  }, [isPrivate])
    
  return (
    <>
      {isPrivate ? <div className='relative'>
        <div className='overlayShade text-sm' >
          <p className='text-gray-light mb-4'>Upgrade to STORY:D Pro for business presentation support with Tableau Cloud visualizations.</p>
          <button className='text-gray-light bg-active-blue py-2 px-4 rounded font-normal'> Get STORY:D Pro </button>
          <p className='text-gray-light mt-4'>STORY:D Free supports Tableau Public for personal presentations.</p>
        </div>
        {children}
      </div> : children}
    </>
  )
}

export default Overlay