import { RefObject, useEffect } from "react";
import { emuToPt, pxToPt } from "helpers";
import { PageElementType } from "types";
import { getCalculatedImageSize } from 'hooks/useImageSizecal';

type GetCalculatedParams = {
  transform: PageElementType["transform"];
  size: PageElementType["size"];
  pageSize: any;
}

export default function useGetCalculatedValues<T extends HTMLElement = HTMLElement>(
  params: GetCalculatedParams, refObj: RefObject<T>,
): {
  newPropotionalWidth: number
  newPropotionalHeight: number
  newPropotionalX: number
  newPropotionalY: number
} {

  const { size: { height, width }, transform: { scaleX, scaleY, translateX, translateY }, pageSize: { width: presentWidth, height: presentHeight } } = params;

  const {newPageWidth, newPageHeight} = getCalculatedImageSize(params.pageSize)
  const currentWindowWidthInPt: number = pxToPt(newPageWidth);
  const currentWindowHeightInPt: number = pxToPt(newPageHeight);

  const presentationWidthInPt = emuToPt(presentWidth.magnitude);
  const presentationHeightInPt = emuToPt(presentHeight.magnitude);

  function updateVideoSize() {
    const {newPageWidth, newPageHeight} = getCalculatedImageSize(params.pageSize)
    const currentWindowWidthInPt = pxToPt(newPageWidth);
    const currentWindowHeightInPt = pxToPt(newPageHeight);
    const { newPropotionalY, newPropotionalX, newPropotionalHeight, newPropotionalWidth } = getCalculatedVideoSize(currentWindowWidthInPt, currentWindowHeightInPt);
    if (refObj.current) {
      refObj.current.style.width = `${newPropotionalWidth}pt`;
      refObj.current.style.height = `${newPropotionalHeight}pt`;
      refObj.current.style.top = `${newPropotionalY}pt`;
      refObj.current.style.left = `${newPropotionalX}pt`;
    }
  }

  function getCalculatedVideoSize(windowWidth = currentWindowWidthInPt, windowHeight = currentWindowHeightInPt) {

    const calculatedWidth = (width.magnitude * scaleX) + height.magnitude;
    const calculatedHeight = (height.magnitude * scaleY) + width.magnitude;
    const calculatedWidthInPt = emuToPt(calculatedWidth);
    const calculatedHeightInPt = emuToPt(calculatedHeight);
    const widthRatio = (calculatedWidthInPt / presentationWidthInPt) * 100;
    const heightRatio = (calculatedHeightInPt / presentationHeightInPt) * 100;
    const newPropotionalWidth = (windowWidth / 100) * widthRatio;
    const newPropotionalHeight = (windowHeight / 100) * heightRatio;
    const calculatedXValue = emuToPt(scaleX + translateX);
    const calculatedYValue = emuToPt(scaleY + translateY);
    const xWidthRatio = (calculatedXValue / presentationWidthInPt) * 100;
    const yHeightRatio = (calculatedYValue / presentationHeightInPt) * 100;
    const newPropotionalX = (windowWidth / 100) * xWidthRatio;
    const newPropotionalY = (windowHeight / 100) * yHeightRatio;

    return {
      newPropotionalWidth,
      newPropotionalHeight,
      newPropotionalX,
      newPropotionalY
    }
  }

  useEffect(() => {
    if (refObj.current) {
      window.addEventListener('resize', updateVideoSize);
      return () => window.removeEventListener('resize', updateVideoSize);
    }
  }, [refObj, params])

  return getCalculatedVideoSize()

} 