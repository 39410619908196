import { useEffect, useState } from "react";

export const useDetectSwipe = (element: React.MutableRefObject<HTMLElement | null | undefined>, { onSwipe, thresholdPixel = 20 }: { onSwipe: (direction: "left" | "right") => void; thresholdPixel: number; }) => {
  const [start, setStart] = useState<{ x: number | null; y: number | null; }>({ x: null, y: null });
  const [op, setOp] = useState<"left" | "right" | null>(null);
  const [horizontalDistance, setHorizontalDistance] = useState<number>(0);
  useEffect(() => {
    const onTouchStart = (e: TouchEvent) => {
      setStart({
        x: e.changedTouches[0].clientX,
        y: e.changedTouches[0].clientY,
      });
    };
    const onTouchMove = (e: TouchEvent) => {
      const currentX = e.changedTouches[0].clientX;
      const currentY = e.changedTouches[0].clientY;
      const distanceX = currentX - (start.x as number);
      const distanceY = currentY - (start.y as number);
      if (Math.abs(distanceX) > Math.abs(distanceY)) {
        setHorizontalDistance(Math.abs(distanceX));
        if (distanceX > 0) {
          setOp("right");
        } else {
          setOp("left");
        }
      }
    };
    const onTouchEnd = () => {
      if (op !== null && horizontalDistance > thresholdPixel) {
        onSwipe(op);
      }
      setOp(null);
      setStart({
        x: null,
        y: null
      });
    };
    if (element.current) {
      element.current.addEventListener("touchstart", onTouchStart);
      element.current.addEventListener("touchmove", onTouchMove);
      element.current.addEventListener("touchend", onTouchEnd);
    }
    return () => {
      if (element.current) {
        element.current.removeEventListener("touchstart", onTouchStart);
        element.current.removeEventListener("touchmove", onTouchMove);
        element.current.removeEventListener("touchend", onTouchEnd);
      }
    };
  }, [element, horizontalDistance, onSwipe, op, start.x, start.y, thresholdPixel]);
};
