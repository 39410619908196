import axios, { AxiosInterceptorManager, AxiosRequestConfig } from "axios";
import { STORY_D_BASE_URL } from "config"

const AxiosGoogleRequest = axios.create({
  baseURL: 'https://slides.googleapis.com/v1'
})

AxiosGoogleRequest.interceptors.request.use((request: any)=>{
  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `Bearer ${localStorage.getItem("googleToken")}`
    }
  };
})

const ApiRequest = axios.create({
  baseURL: STORY_D_BASE_URL + "/addon",
})

const StoryApiRequest = axios.create({
  baseURL: STORY_D_BASE_URL + "/story",
})

ApiRequest.interceptors.request.use((request: any) => {
  return {
    ...request,
    headers: {
      ...request.headers,
      'x-google-token': localStorage.getItem("googleToken"),
      'Authorization': `Bearer ${localStorage.getItem("token")}`,
    }
  };
})

StoryApiRequest.interceptors.request.use((request: any) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token") || "";
  return {
    ...request,
    headers: {
      ...request.headers,
      'Authorization': `Bearer ${token}`,
    }
  };
})

export { AxiosGoogleRequest, ApiRequest, StoryApiRequest };