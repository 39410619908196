import React, { useEffect, useRef } from 'react'
import { emuToPt, pxToPt } from 'helpers';
import { PageElementType, VideoSources } from 'types'
import useGetCalculatedValues from 'hooks/useGetCalculatedValue';

const VideoElement: React.FC<{
  videoData: PageElementType,
  presentationData: any,
}> = ({ presentationData, videoData }) => {

  const videoWrapperRef = useRef<HTMLDivElement>(null)

  const { pageSize } = presentationData;

  const { newPropotionalHeight, newPropotionalWidth, newPropotionalX, newPropotionalY } = useGetCalculatedValues({ ...videoData, pageSize }, videoWrapperRef);

  if (!videoData.video) return null;

  const { video: { id, source, url } } = videoData;

  const srcUrl = source === VideoSources.YOUTUBE ? `https://www.youtube.com/embed/${id}` : url;
  const styles: React.CSSProperties = {
    width: `${newPropotionalWidth}pt`,
    height: `${newPropotionalHeight}pt`,
    left: `${newPropotionalX}pt`,
    top: `${newPropotionalY}pt`,
  }
  return (
    <div ref={videoWrapperRef} className='videoElement'
      style={styles}
    >
      <iframe
        src={srcUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )
}


export default VideoElement