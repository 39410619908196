import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import GoogleLoginButton from "components/GoogleLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "config";
import Loader from "components/Loader";
import { getPresentationThumbnails, getSlidesData } from "apis/googlePresentations";
import SlidePresenter from "components/SlidePresenter";
import { SlideShow } from "./components/SlideShow/SlideShow";
import { getStoryData } from "apis/storydPresentations";

export default function AppInit() {

  const [slideHtmlData, setSlideHtmlData] = useState<Array<any>>([]);
  const [showLoginButton, setLoggedIn] = useState(false);
  const [presentationData, setPresentationData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [needAuth, setAuthStatus] = useState(false);

  const { presentationId, storyId } = useMemo<{ presentationId: string; storyId: string }>(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const presentationId = params.get("presentationId") || "";
    const storyId = params.get("storyId") || "";
    const token = params.get("token") || "";
    localStorage.setItem('token', token);
    return { presentationId, storyId }
  }, []);


  const getPresentationDetails = async () => {
    setLoading(true);
    try {
      // const presentationData = await getPresentationData(presentationId);
      const slidesData = await getSlidesData(presentationId);
      setPresentationData(slidesData);
    } catch (err:any) {
      if(err?.response?.data?.status === 401) {
        localStorage.removeItem("googleToken");
        setAuthStatus(true);
        setLoggedIn(true)
      }
      setLoading(false);
    }
  };

  const getAllThumbnailData = async (SlidesData: Array<any>) => {
    const promiseArray: any = SlidesData.map((slideInfo: { objectId: string }) => {
      return getPresentationThumbnails(presentationId, slideInfo?.objectId);
    })
    const allSlidesResponse: any[] = await (Promise as any).allSettled(promiseArray);
    const responsedGot = allSlidesResponse.map((promiseRes, i) => promiseRes.value || {});
    setSlideHtmlData(responsedGot.map((imageInfoData: any, index: number) => {
      const img = new Image();
      img.src = imageInfoData.contentUrl;
      img.referrerPolicy = "no-referrer";
      return ({ ...imageInfoData, ...SlidesData[index] })
    }));
    setLoading(false);
  }

  useEffect(() => {
    if (presentationData && !storyId) {
      getAllThumbnailData(presentationData.slides)
    }
  }, [presentationData])

  const onSuccess = async () => {
    setLoggedIn(false);
    if (presentationId) getPresentationDetails()
  };

  const onFailure = () => {
    console.log('failed:');
  };

  useEffect(()=>{
    (async ()=> {
      if (storyId) {
        setLoading(true);
        const storyData = await getStoryData(storyId);
        setPresentationData(storyData);
        setLoading(false);
      }
    })()
  }, [storyId])

  return (
    <>
      {loading && <Loader />}
      {!storyId ? <GoogleOAuthProvider
        onScriptLoadSuccess={() => {
          setLoading(false);
          setLoggedIn(true);
        }}
        onScriptLoadError={() => setLoading(false)}
        clientId={GOOGLE_CLIENT_ID}>
        {showLoginButton ? <GoogleLoginButton
          onFailure={onFailure}
          onSuccess={onSuccess}
        /> :
          <SlidePresenter
            presentationData={presentationData}
            slideHtmlData={slideHtmlData} />}
      </GoogleOAuthProvider>:
        <SlideShow presentationData={presentationData} />
      }
    </>
  );
}
