import React, { CSSProperties } from 'react';
import ClipLoader from "react-spinners/ClipLoader";

const override: CSSProperties = {
  // display: "block",
  // margin: "0 auto",
  borderWidth: "3px",
  borderColor: "rgb(7, 77, 217) rgb(7, 77, 217) #ffffff00 ",
};

const Loader = () => {
  return (
    <div className='w-full flex items-center justify-center absolute z-50 h-full'>
      <div className='h-full w-full absolute z-40 blur-md bg-[#ffffff3b]'></div>
      <ClipLoader
        loading={true}
        cssOverride={override}
        size={72}
        speedMultiplier={1}
      />
    </div>
  )
}


export default Loader;