import { AxiosGoogleRequest, ApiRequest } from "utility/axios";



export const getPresentationData = async (presentationId: string) => {
  try {
    const response = await AxiosGoogleRequest.get(`/presentations/${presentationId}`)
    return response.data;
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getPresentationThumbnails = async (presentationId: string, slideObjectId: string) => {
  try {
    const response = await AxiosGoogleRequest.get(`/presentations/${presentationId}/pages/${slideObjectId}/thumbnail`)
    return response.data;
  } catch (err) {
    console.log(err)
  }
}

export const getSlidesData = async (presentationId: string) => {
  try {
    const response = await ApiRequest.get(`/presenter?presentation_id=${presentationId}`)
    return response.data.data
  } catch (err) {
    console.log(err)
    throw err
  }
}