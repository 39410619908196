import AppInit from './AppInit';


function App() {

  return (
    <div className="main-wrapper flex flex-col">
        <AppInit />
    </div>
  );
}

export default App;
