import { useCallback, useEffect, useState } from 'react';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import Loader from 'components/Loader';
import { GOOGLE_CLIENT_ID } from "config";
import { explainSvg } from 'assets/images';

interface GoogleLoginButtonI {
  onSuccess: () => void,
  onFailure: () => void,
}

const GoogleLoginButton: React.FC<GoogleLoginButtonI> = ({ onSuccess, onFailure }) => {

  const handleSuccess = (res: any) => {
    const { access_token } = res;
    localStorage.setItem("googleToken", access_token);
    onSuccess();
  }

  const login = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/presentations.readonly",
    flow: 'implicit',
    onError: onFailure,
    onSuccess: handleSuccess,
  })

  const handleLogin = () => {
    login();
  }

  const handleExistingSession = () => {
    const currentToken = localStorage.getItem("googleToken");
    if (currentToken) {
      onSuccess();
    }
  }

  useEffect(() => {
    handleExistingSession();
  }, [])

  return (
    <div className='flex flex-col items-center w-full justify-center' >
      <div className='flex flex-col items-center justify-center'>
        <img className='w-96' src={explainSvg} />
        <h4 className='font-bold text-2xl'>You’re almost there!</h4>
        <p className='text-sm text-gray-secondary mt-4 mb-8 w-[304px] text-center' >Before you start presenting, please authorize STORY:D Tableau to access your Google account.</p>
      </div>
      <div className='relative' onClick={handleLogin}>
        <div className='absolute h-[42px] w-full z-20 cursor-pointer '></div>
        <GoogleLogin
          onSuccess={() => { }}
        />
      </div>
    </div>
  )
}

export default GoogleLoginButton