import { RefObject, useEffect } from "react";
import { emuToPt, emuToPx, pxToPt } from "helpers";
import { PageElementType } from "types";

export function getCalculatedImageSize(pageSize:any) {

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const{ width: presentWidth, height: presentHeight } = pageSize;

  const heightWidthRatio = emuToPx(presentHeight.magnitude)/emuToPx(presentWidth.magnitude)*100;
  const isWidthIsLess = windowHeight/windowWidth * 100 >= heightWidthRatio;
  let newPageWidth = 0;
  let newPageHeight = 0;
  if(isWidthIsLess) {
    newPageWidth = windowWidth;
    newPageHeight = newPageWidth/100*heightWidthRatio;
  } else {
    newPageHeight = windowHeight;
    newPageWidth = newPageHeight * 100 / heightWidthRatio;
  }

  return {
    newPageWidth,
    newPageHeight,
  }
}

export default function useImageSizeCalc<T extends HTMLElement = HTMLElement>(
  pageSize: any, refObj: RefObject<T>,
): {
  newPageWidth: number
  newPageHeight: number
} {



  function updateVideoSize() {
    const { newPageHeight, newPageWidth } = getCalculatedImageSize(pageSize);
    if (refObj.current) {
      refObj.current.style.width = `${newPageWidth}px`;
      refObj.current.style.height = `${newPageHeight}px`;
    }
  }

  useEffect(() => {
    if (refObj.current) {
      window.addEventListener('resize', updateVideoSize);
      return () => window.removeEventListener('resize', updateVideoSize);
    }
  }, [refObj])

  return getCalculatedImageSize(pageSize)
} 