type OStypes = 'iPhone OS' | 'Mac OS' | ''
type Brower = 'Safari' | ''
type Device = 'iPad' | ''
const detectDevice = (): { os: OStypes; browser: Brower, device: Device } => {
    let os: OStypes = ''
    let device: Device = ''
    if (navigator.userAgent.match(/iPhone OS/)) {
        os = 'iPhone OS'
    }
    else if (navigator.userAgent.match(/Mac OS/)) {
        os = 'Mac OS'
    }

    if (navigator.userAgent.match(/iPad/)) {
        device = 'iPad'
    }

    return {
        os,
        browser: navigator.userAgent.match(/Safari/) ? 'Safari' : '',
        device
    };
};

export default detectDevice;
