import { useEffect, useState } from "react";

type WindoDimensions = {
    width: number;
    height: number;
};

function getWindowDimensions(): WindoDimensions {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

const useIsMobile = () => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(),
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (windowDimensions.width < 1024) {
        return true;
    }
    return false;
};

export default useIsMobile;