import React, { CSSProperties, useState } from "react";
import Loader from "components/Loader";

export const ImageView = (props: { url: string; style?: CSSProperties; className?: string }) => {
  const [isLoading, setIsLoading] = useState(true);
  if (isLoading) {
    <Loader />;
  }
  return (
    <img
      src={props.url}
      alt=""
      onLoad={() => {
        setIsLoading(true);
      }}
      className={`h-screen w-screen object-contain ${props.className || ""}`}
      style={props.style}
    />
  );
};
