import React, { Dispatch, RefObject, useEffect, useRef } from 'react'
import { emuToPt, pxToPt } from 'helpers';
import { PageElementType, VideoSources } from 'types'
import useImageSizeCalc from 'hooks/useImageSizecal';

type GetCalculatedParams = {
  transform: PageElementType["transform"];
  size: PageElementType["size"];
  pageSize: any;
}


const SlideImageElement: React.FC<{
  children: any,
  width?: number,
  height?: number,
  src?: string,
  pageSize: any;
}> = ({ children, height, src, width, pageSize }) => {

  const imageRef = useRef<HTMLImageElement>(null);

  const { newPageWidth, newPageHeight } = useImageSizeCalc(pageSize, imageRef)

  const imageSizeStyle:React.CSSProperties = {
    width: `${newPageWidth}px`,
    height: `${newPageHeight}px`,
  }

  return <div className="image-wrapper" >
    <img ref={imageRef} style={imageSizeStyle} referrerPolicy="no-referrer"  src={src} alt="image" />
    {children}
  </div>
}

export default SlideImageElement;