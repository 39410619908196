import { StoryApiRequest } from "utility/axios"

export const getStoryData = async (storyId: string) => {
    try {
        const response = await StoryApiRequest.get(`/presenter?story_id=${storyId}`)
        return response.data.data
    } catch (err) {
        console.log(err)
        throw err
    }
}