import { tableuaLogo } from 'assets/images';
import React, { useEffect, useMemo, useState, useRef, ReactHTMLElement } from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { SlideDataType, sourceType, VideoSources } from 'types';
import Overlay from './Overlay';
import SlideElement from './SlideElementWrap';
import SlideImageElement from './SlideImageElement';
import SlideNavigator from './SlideNavigator'
import VideoElement from './videoElement';

const SlidePresenter: React.FC<{
  slideHtmlData: Array<SlideDataType>,
  presentationData: any,
}> = ({ slideHtmlData, presentationData }) => {

  const [currentSlide, updateSlide] = useState<number>(1);
  const [sizeUpdated, updateSize] = useState<null | { width: any, height: any }>(null)
  const elementRef = useRef<HTMLDivElement>(null);
  const customElementRef = useRef<HTMLDivElement>(null);

  const fullScreenHandler = useFullScreenHandle();

  const goToNextSlide = (totalLength: number) => {
    updateSlide((slideNo) => {
      return slideNo + 1 > totalLength ? slideNo : slideNo + 1;
    })
  }

  const goToPreviousSlide = () => {
    updateSlide((slideNo) => {
      return slideNo - 1 || slideNo;
    })
  }

  const handleKeyRight = (e: KeyboardEvent) => {
    if (e.key === "ArrowRight") {
      if (slideHtmlData.length) goToNextSlide(slideHtmlData.length)
    }
  }

  const handleKeyLeft = (e: KeyboardEvent) => {
    if (e.key === "ArrowLeft") {
      if (slideHtmlData.length) goToPreviousSlide()
    }
  }

  useEffect(() => {
    if (slideHtmlData.length) {
      window.addEventListener('keydown', handleKeyRight);
      window.addEventListener('keydown', handleKeyLeft);
    }
  }, [slideHtmlData])


  const RenderedImageJSX = useMemo(() => {

    const sildeData = slideHtmlData[currentSlide - 1];

    if (!slideHtmlData.length || !sildeData?.pageElements) return null;

    const isCustomSlide = !!sildeData?.chart;
    const videoElementData = sildeData?.pageElements?.filter((slideData) => slideData?.video);

    const customSlideElementData = isCustomSlide && sildeData?.pageElements?.find((data) => data?.objectId === sildeData?.chart?.object_id)

    const videoJSXElements = videoElementData?.map((videoData) => videoData.video &&
      <VideoElement
        key={videoData.objectId}
        videoData={videoData}
        presentationData={presentationData}
      />)

    const tablueJsx = customSlideElementData && <SlideElement
      refObject={customElementRef}
      inFullScreen={fullScreenHandler.active}
      pageElementInfo={customSlideElementData}
      presentationData={presentationData}
      sildeData={sildeData}
    />



    const imageJsx =
      <SlideImageElement
        width={sildeData?.width}
        height={sildeData?.height}
        pageSize={presentationData?.pageSize}
        src={sildeData?.contentUrl} >
        <>{videoJSXElements}</>
        <>{tablueJsx}</>
      </SlideImageElement>

    return imageJsx;

  }, [slideHtmlData, currentSlide, customElementRef, sizeUpdated, fullScreenHandler.active])

  return (
    <FullScreen
      handle={fullScreenHandler}
    >
      <div
        onClick={() => {
          goToNextSlide(slideHtmlData.length);
        }}
        className="slide-container" ref={elementRef}>
        {!!slideHtmlData.length && <>
          <SlideNavigator
            handleFullScreen={fullScreenHandler}
            goToNextSlide={goToNextSlide}
            goToPreviousSlide={goToPreviousSlide}
            currentSlideNo={currentSlide}
            totalSlideCount={slideHtmlData.length}
          />
          {RenderedImageJSX}
        </>}
      </div>
    </FullScreen>
  )
}

export default SlidePresenter