export enum sourceType {
  POWERBI = "POWERBI",
  TABLEAU = "TABLEAU",

}
export type SlideDataType = {
  contentUrl: string;
  chart?: {
    object_id: string;
    data_url: string;
    type: sourceType,
  };
  height: number;
  objectId: string;
  pageElements: Array<PageElementType>;
  slideProperties: any;
  width: number;
  access: boolean;
}

export type PageElementType = {
  objectId: string;
  shape: any;
  size: {
    height: {
      magnitude: number;
      unit: string;
    },
    width: {
      magnitude: number;
      unit: string;
    },
  },
  transform: {
    scaleX: number;
    scaleY: number;
    translateX: number;
    translateY: number;
    unit: string;
  },
  video?: {
    id: string;
    source: string;
    url: string;
    videoProperties: any;
  }
}

export enum VideoSources {
  YOUTUBE = "YOUTUBE"
}