import { RefObject, useEffect } from "react";
import { emuToPt, pxToPt } from "helpers";
import { PageElementType } from "types";
import { getCalculatedImageSize } from "./useImageSizecal";

type GetCalculatedParams = {
  transform: PageElementType["transform"];
  size: PageElementType["size"];
  pageSize: any;
}

export default function useCustomCalc<T extends HTMLElement = HTMLElement>(
  params: GetCalculatedParams, refObj: RefObject<T>,
): {
  newPropotionalWidth: number
  newPropotionalHeight: number
  newPropotionalX: number
  newPropotionalY: number
} {

  const { size: { height, width }, transform: { scaleX, scaleY, translateX, translateY }, pageSize: { width: presentWidth, height: presentHeight } } = params;


  const {newPageWidth, newPageHeight} = getCalculatedImageSize(params.pageSize)
  const currentWindowWidthInPt: number = pxToPt(newPageWidth);
  const currentWindowHeightInPt: number = pxToPt(newPageHeight);


  const presentationWidthInPt = emuToPt(presentWidth.magnitude);
  const presentationHeightInPt = emuToPt(presentHeight.magnitude);

  function updateElementSize() {
    const {newPageWidth, newPageHeight} = getCalculatedImageSize(params.pageSize)
    const currentWindowWidthInPt = pxToPt(newPageWidth);
    const currentWindowHeightInPt = pxToPt(newPageHeight);
    const { newPropotionalY, newPropotionalX, newPropotionalHeight, newPropotionalWidth } = getCalculatedElementSize(currentWindowWidthInPt, currentWindowHeightInPt);
    if (refObj.current) {
      refObj.current.style.width = `${newPropotionalWidth}pt`;
      refObj.current.style.height = `${newPropotionalHeight}pt`;
      refObj.current.style.top = `${newPropotionalY}pt`;
      refObj.current.style.left = `${newPropotionalX}pt`;
    }
  }

  function getCalculatedElementSize(windowWidth:number, windowHeight:number) {
    const calculatedWidth = (width.magnitude * scaleX);
    const calculatedHeight = (height.magnitude * scaleY);
    const calculatedWidthInPt = emuToPt(calculatedWidth);
    const calculatedHeightInPt = emuToPt(calculatedHeight);

    const widthRatio = (calculatedWidthInPt / presentationWidthInPt) * 100;
    const heightRatio = (calculatedHeightInPt / presentationHeightInPt) * 100;
    const newPropotionalWidth = ((windowWidth / 100) * widthRatio) + pxToPt(1);
    const newPropotionalHeight = ((windowHeight / 100) * heightRatio) + pxToPt(1);
    const calculatedXValue = emuToPt(scaleX + translateX);
    const calculatedYValue = emuToPt(scaleY + translateY);
    const xWidthRatio = (calculatedXValue / presentationWidthInPt) * 100;
    const yHeightRatio = (calculatedYValue / presentationHeightInPt) * 100;
    const newPropotionalX = ((windowWidth / 100) * xWidthRatio) - pxToPt(0.5);
    const newPropotionalY = ((windowHeight / 100) * yHeightRatio) - pxToPt(1);

    return {
      newPropotionalWidth,
      newPropotionalHeight,
      newPropotionalX,
      newPropotionalY
    }
  }

  useEffect(() => {
    if (refObj.current) {
      window.addEventListener('resize', updateElementSize);
    }
    return () => {
      window.removeEventListener('resize', updateElementSize);
    } 
  }, [refObj.current, params])

  return getCalculatedElementSize(currentWindowWidthInPt, currentWindowHeightInPt)

} 