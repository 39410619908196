import React, { useEffect, useState, useRef } from 'react';
const { tableau } = window;

function TabluaeElement({ dataUrl, pageElementInfo, inFullScreen }) {

  // these are just guesses unti they are overriden in onFirstVizSizeKnown (if its available, doesnt seem to be available on all dashboards)
  const VizNativeWidth = useRef(1366);
  const VizNativeHeight = useRef(768);
  const VizReady = useRef(false);
  const bAllowUpscaling = useRef(true);
  const MaxUpscaleRatio = useRef(2);
  const LastScale = useRef(1);
  const hasScaledAtLeastOnce = useRef(false);
  const TargetRef = useRef(null);
  const TargetWrapper = useRef(null);


  const [BaseOptions] = useState({
    hideTabs: true,
    hideToolbar: false,
    hideEditButton: true,
    hideCloseButton: true,
  })

  const ScaleViz = () => {
    setTimeout(() => {

      console.log("scaling called >>>")
      const DashboardTarget = TargetRef;
      const DashboardWrapper = TargetWrapper;

      // note: u cant hide the toolbar for tableau public vizuals. they just dont let u
      // hence this
      const bIsToolbarPresent = !BaseOptions.hideToolbar || dataUrl.startsWith('https://public');

      if (!!DashboardTarget.current && !!DashboardWrapper.current) {
        // toolbar height for tableau public is exactly 27 px it seems
        let DesiredWidth = VizNativeWidth.current;
        let DesiredHeight = (VizNativeHeight.current) + (bIsToolbarPresent ? 27 : 0);

        const WrapperWidth = bAllowUpscaling.current ? DashboardTarget.current.clientWidth : DashboardWrapper.current.clientWidth;
        const WrapperHeight = bAllowUpscaling.current ? DashboardTarget.current.clientHeight : DashboardWrapper.current.clientHeight;

        const ScaleByWidth = () => {
          let Scale = WrapperWidth / DesiredWidth;

          // special logic for upscaling
          if (bAllowUpscaling.current) {
            DesiredWidth = DashboardWrapper.current.clientWidth;
            DesiredHeight = DashboardWrapper.current.clientHeight + (bIsToolbarPresent ? 27 : 0);
            Scale = DesiredWidth / WrapperWidth;
            const Tolerance = 0;
            const WindowHeight = DashboardWrapper.current.clientHeight;
            const WindowWidth = DashboardWrapper.current.clientWidth;
            const CurrentWidth = DashboardTarget.current.clientWidth;
            const CurrentHeight = DashboardTarget.current.clientHeight;
            const NewHeightCandidate = Scale * CurrentHeight;

            if (NewHeightCandidate > (WindowHeight + Tolerance)) {
              const Diff = Math.abs(NewHeightCandidate - WindowHeight) + Tolerance;
              DesiredWidth = DesiredWidth - Diff;
              Scale = DesiredWidth / WrapperWidth;
            }

            const NewWidthCandidate = Scale * CurrentWidth;
            if (NewWidthCandidate > (WindowWidth + Tolerance)) {
              const Diff = Math.abs(NewWidthCandidate - WindowWidth) + Tolerance;
              DesiredWidth = DesiredWidth - Diff;
              Scale = DesiredWidth / WrapperWidth;
            }
            Scale = Math.min(MaxUpscaleRatio.current, Scale);
          }
          Scale = parseFloat(Scale.toString().substring(0, 5));
          LastScale.current = Scale;
          DashboardTarget.current.style.transform = `scale(${Scale})`;
          if (!hasScaledAtLeastOnce.current) {
            // call twice on first load because LastScale.current var wasnt correct the first time
            console.log("scaling on hasScaledAtLeastOnce ScaleByWidth false >>>")
            hasScaledAtLeastOnce.current = true;
            setTimeout(() => ScaleViz());
          }
        };
        const ScaleByHeight = () => {
          let Scale = WrapperHeight / DesiredHeight;

          // special logic for upscaling
          if (bAllowUpscaling.current) {
            DesiredWidth =  DashboardWrapper.current.clientWidth;
            DesiredHeight = (DashboardWrapper.current.clientHeight) + (bIsToolbarPresent ? 27 : 0);
            Scale = DesiredHeight / WrapperHeight


            const Tolerance = 0;
            const WindowHeight = DashboardWrapper.current.clientHeight;
            const WindowWidth = DashboardWrapper.current.clientWidth;
            const CurrentWidth = DashboardTarget.current.clientWidth;
            const CurrentHeight = DashboardTarget.current.clientHeight;
            const NewHeightCandidate = Scale * CurrentHeight;

            if (NewHeightCandidate > (WindowHeight + Tolerance)) {
              const Diff = Math.abs(NewHeightCandidate - WindowHeight) + Tolerance;
              DesiredHeight = DesiredHeight - Diff;
              Scale = DesiredHeight / WrapperHeight;
            }

            const NewWidthCandidate = Scale * CurrentWidth;
            if (NewWidthCandidate > (WindowWidth + Tolerance)) {
              const Diff = Math.abs(NewWidthCandidate - WindowWidth) + Tolerance;
              DesiredWidth = DesiredWidth - Diff;
              Scale = DesiredHeight / WrapperHeight;
            }
            Scale = Math.min(MaxUpscaleRatio.current, Scale);
          }
          Scale = parseFloat(Scale.toString().substring(0, 5));
          LastScale.current = Scale;
          DashboardTarget.current.style.transform = `scale(${Scale})`;
          if (!hasScaledAtLeastOnce.current) {
            // call twice on first load because LastScale.current var wasnt correct the first time
            console.log("scaling on hasScaledAtLeastOnce ScaleByHeight false >>>")
            hasScaledAtLeastOnce.current = true;
            setTimeout(() => ScaleViz());
          }
        }

        const bIsHeightConstrained = WrapperHeight < DesiredHeight;
        const bIsWidthConstrained = WrapperWidth < DesiredWidth;

        if ((bIsHeightConstrained && bIsWidthConstrained) || bAllowUpscaling.current) {
          // if both dimensions are constrained, pick whichever dimension is "more constrained" and use that to scale
          const widthBias = 50;
          const bDoWidth = bAllowUpscaling.current ? ((DashboardTarget.current.clientWidth * LastScale.current) + widthBias > DashboardWrapper.current.clientWidth) : WrapperWidth < WrapperHeight;

          if (bDoWidth) {
            ScaleByWidth();
          } else {
            ScaleByHeight();
          }
        }
        else if (bIsWidthConstrained) {
          ScaleByWidth();
        }
        else if (bIsHeightConstrained) {
          ScaleByHeight();
        }
        else {
          // no scaling needed, reset
          DashboardTarget.current.style.transform = `scale(${1.0})`;
          LastScale.current = 1;
        }
      }
      else if (!!DashboardTarget) {
        DashboardTarget.current.style.transform = `scale(${1.0})`;
        LastScale.current = 1;
      }
    })
  }


  const OnVizInteractive = () => {
    VizReady.current = true;
    console.log("scaling on OnVizInteractive >>>")
    setTimeout(() => ScaleViz());
  };


  const OnFirstVizSizeKnown = (Ev) => {
    const Size = Ev.getVizSize().sheetSize?.maxSize;

    console.log("test data >>> OnFirstVizSizeKnown", Size)
    if (Size && Size.height && Size.width) {
      VizNativeHeight.current = Size.height;
      VizNativeWidth.current = Size.width;
    }
  }

  useEffect(() => {
    window.addEventListener('resize', (ev) => {
      setTimeout(() => ScaleViz())
    });

    return window.removeEventListener('resize', (ev) => {
      setTimeout(() => ScaleViz())
    });
  }, [ScaleViz, inFullScreen])




  const initViz = () => {
    const options = {
      device: "desktop",
      hideToolbar: BaseOptions.hideToolbar,
      hideTabs: BaseOptions.hideTabs,
      onFirstInteractive: OnVizInteractive,
      onFirstVizSizeKnown: OnFirstVizSizeKnown,
    }

    let viz = tableau.VizManager.getVizs()[0];
    if (viz) {
      try {
        viz.dispose();
      } catch (err) {
        console.log("err in disposs", err)
      }
    }
    new tableau.Viz(TargetRef.current, dataUrl, options);
  };


  useEffect(() => {
    let viz = tableau.VizManager.getVizs()[0];
    console.log("test error check", viz);
    initViz();
    return () => {
      let viz = tableau.VizManager.getVizs()[0];
      if (viz) {
        try {
          // console.log("calling disposs", viz);
          viz.dispose();
        } catch (err) {
          // console.log("err in disposs", err)
        }
      }
    }
  }, [dataUrl, pageElementInfo]);

  useEffect(() => {
    // console.log("on component mount >>>", dataUrl);
  }, []);


  return (
    <div id="tabluaeWrapperCt" ref={TargetWrapper} className='tabluaeWrapper' >
      <div id="tableauViz" ref={TargetRef} ></div>
    </div>
  )
}

export default TabluaeElement