export const emuToPt = (emuValue:number):number => {
  // 12,700 EMUs per point
  return emuValue / 12700;
}
export const ptToEmu = (emuValue:number):number => {
  // 12,700 EMUs per point
  return emuValue * 12700;
}

export const ptToPx = (ptValue:number):number => {
 // 0.75pt =	1px
 return ptValue / 0.75;
}

export const pxToPt = (pxValue:number):number => {
  // 1px = 0.75pt
  return 0.75 * pxValue ;
 }

export const emuToPx = (emuValue:number):number => ptToPx(emuToPt(emuValue));

export const pxToEmu = (pxValue:number):number =>  ptToEmu(pxToPt(pxValue));