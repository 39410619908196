/* eslint-disable jsx-a11y/alt-text */
import { exitIcon, expand,leftarrow, rightarrow } from 'assets/images';
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect, useMemo, useRef } from 'react'

interface SlideNavigatorI {
  totalSlideCount: number;
  currentSlideNo: number;
  goToNextSlide: (count: number) => void,
  goToPreviousSlide: () => void,
  handleFullScreen: {
    active: boolean;
    enter: () => Promise<void>;
    exit: () => Promise<void>;
    node: React.MutableRefObject<HTMLDivElement | null>;
  },
}

function SlideNavigator({ currentSlideNo, totalSlideCount, goToNextSlide, goToPreviousSlide, handleFullScreen }: SlideNavigatorI) {

  const menuRef = useRef<HTMLDivElement>(null);

  const { active, enter, exit } = handleFullScreen;

  const isMobile = useIsMobile()

  const timeOut = useRef<any>()

  useEffect(() => {
    if (menuRef.current) menuRef.current.style.opacity = "0";
    menuRef.current?.addEventListener("mouseout", (e) => {
      if (menuRef.current) menuRef.current.style.opacity = "0";
    })
    menuRef.current?.addEventListener("mouseover", () => {
      if (menuRef.current) menuRef.current.style.opacity = "1";
    })
    if(isMobile) {
      document.addEventListener("click", ()=> {
        if (menuRef.current) menuRef.current.style.opacity = "1";
        clearTimeout(timeOut.current)
        timeOut.current = setTimeout(()=>{
          if (menuRef.current) menuRef.current.style.opacity = "0";
        }, 3000)
      })
    }
  }, [isMobile])



  const isLeftDisabled = useMemo(() => currentSlideNo <= 1, [currentSlideNo, totalSlideCount]);
  const isRightDisabled = useMemo(() => currentSlideNo >= totalSlideCount, [currentSlideNo, totalSlideCount]);

  const closeWindow = ()=> {
    window.close();
  }

  if (isMobile) {
    return <div className='flex flex-col items-center fixed bottom-4 w-full text-white' ref={menuRef}>
      <div className='  text-sm'>Slide {currentSlideNo}</div>      
    <div className='flex w-full flex-row justify-around'>
        {!!window.opener && <div onClick={closeWindow} className='bg-gray-secondary rounded-full w-[50px] h-[50px] flex justify-center items-center'>
          {
            <img className='h-[18px]' src={exitIcon} />
          }
        </div>}
        <div onClick={() => goToPreviousSlide()} className='bg-gray-secondary rounded-full w-[50px] h-[50px] flex justify-center items-center'>
          <img className={`h-[22px]	${isLeftDisabled ? "opacity-50" : ""}`} src={leftarrow} />
      </div>
        <div onClick={() => goToNextSlide(totalSlideCount)} className='bg-gray-secondary rounded-full w-[50px] h-[50px] flex justify-center items-center'>
          <img className={`h-[22px] ${isRightDisabled ? "opacity-50" : ""}`} src={rightarrow} />
      </div>
        {document.fullscreenEnabled && <div onClick={() => {
          if (active) {
            exit()
          } else {
            enter()
          }
        }} className='bg-gray-secondary rounded-full w-[50px] h-[50px] flex justify-center items-center'>
        {
          active ?
              <i className="fa-solid fa-down-left-and-up-right-to-center" ></i>
              : <img className='h-[20px]' src={expand} />
        }
      </div>}
    </div>
    </div>
  }

  return (
    <div onClick={(e) => {
      e.stopPropagation();
    }} className='navAllWrapper z-50' ref={menuRef}>
      <div className='navContainer  flex px-2 py-3 shadow absolute bottom-4 left-[50%] translate-x-[-50%] text-white rounded-lg z-10 w-[200]'>
        <div className='navWrapper w-full items-center font-bold flex flex-row'>
          {!!window.opener && <div onClick={closeWindow} className='bg-gray-secondary flex flex-row p-2 pr-3 items-center rounded-md cursor-pointer cursor-pointer w-[98px] mr-[10px] justify-around h-[44px]'>
            <img className='h-[18px]' src={exitIcon} />
            <p className='text-[14px]'> Exit </p>
          </div>}
          <div className='bg-gray-secondary flex flex-row p-2 items-center rounded-md w-[152px] mr-[10px] justify-around h-[44px] cursor-default'>
            <img onClick={() => goToPreviousSlide()} className={`mr-1 h-[22px] cursor-pointer	${isLeftDisabled ? "opacity-50 cursor-not-allowed	": ""}`} src={leftarrow} />
            <p className='!w-auto text-[14px]'>Slide {currentSlideNo}</p>
            <img onClick={() => goToNextSlide(totalSlideCount)} className={`mr-1 h-[22px] cursor-pointer ${isRightDisabled ? "opacity-50 cursor-not-allowed	" : ""}`} src={rightarrow} />
          </div>
          <div
            onClick={() => {
              if (active) {
                exit()
              } else {
                enter()
              }
            }}
            className='bg-gray-secondary flex flex-row p-2 items-center rounded-md cursor-pointer w-[142px] justify-around h-[44px]'>
            {active ? <i className="fa-solid fa-down-left-and-up-right-to-center mr-2"></i> : <img className='h-[20px]' src={expand} />}<p className='text-[14px]'> {active ? 'Exit Fullscreen' : 'Fullscreen'} </p></div>
        </div>
      </div>
    </div>
  )
}

export default SlideNavigator;