import React, { Dispatch, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { emuToPt, pxToPt } from 'helpers';
import { PageElementType, sourceType, VideoSources } from 'types'
import useCustomCalc from 'hooks/useCustomCal';
import Overlay from './Overlay';
import { tableuaLogo } from 'assets/images';
import TabluaeElement from './TabluaeElement';

const SlideElement: React.FC<{
  pageElementInfo: PageElementType,
  presentationData: any,
  refObject: RefObject<HTMLDivElement>,
  sildeData: any,
  inFullScreen: boolean,
}> = ({ presentationData, pageElementInfo, refObject, sildeData, inFullScreen }) => {

  const { pageSize } = presentationData;

  const { newPropotionalHeight, newPropotionalWidth, newPropotionalX, newPropotionalY } = useCustomCalc({ ...pageElementInfo, pageSize }, refObject);

  const styles:React.CSSProperties = {
    width: `${newPropotionalWidth}pt`,
    height: `${newPropotionalHeight}pt`,
    left: `${newPropotionalX}pt`,
    top: `${newPropotionalY}pt`,
  }

  const contentType = sildeData?.chart?.type;
  const isPrivateUrl = !sildeData?.access
  let data = "";

  const isTableauType = contentType === sourceType.TABLEAU;
  const isPowerBiType = contentType === sourceType.POWERBI;


  if (isPowerBiType)
    data = `<iframe class="flex flex-auto h-full" src=${sildeData?.chart?.data_url} allowFullScreen></iframe>`;

  return (
    <div ref={refObject} className='customElement'
      style={styles}
    >
      <Overlay isPrivate={isPrivateUrl} containerRef={refObject} >
        {!isPrivateUrl ?
          (isPowerBiType ? <div className={'flex items-center justify-center h-full'} dangerouslySetInnerHTML={{ __html: data }} /> :
            (isTableauType && <TabluaeElement inFullScreen={inFullScreen} pageElementInfo={pageElementInfo} dataUrl={sildeData?.chart?.data_url} />))
          :
          <div className='flex items-center justify-center'><img className='!w-4/5 !h-[600px]' src={tableuaLogo} /></div>}
      </Overlay>
    </div>
  )
}


export default SlideElement