import SlideNavigator from "components/SlideNavigator";
import  { LegacyRef, useCallback, useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ImageView } from "./ImageView";
import { useDetectSwipe } from "../../hooks/useDetectSwipe";
import detectDevice from "utility/detectDevice";

const info = detectDevice();
export const SlideShow = (props: { presentationData: { slides: any; }; } | undefined) => {
  const slides = props?.presentationData?.slides || [];
  const [index, setIndex] = useState(0);
  const slide = slides[index];

  useEffect(()=> {
    slides.forEach((item: any)=>{
      const image = new Image(item)
      image.src = item.thumbnail_url
    })
  }, [slides])

  const goToNextSlide = useCallback(() => {
    setIndex(index => index + 1 === slides.length ? index : index + 1);
  }, [slides.length])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          goToPreviousSlide()
          break;
        case "ArrowRight":
          goToNextSlide()
          break;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

  }, [goToNextSlide, index, slides.length]);

  const fullScreenHandler = useFullScreenHandle();

  const containerRef = useRef<HTMLElement | null>()

  useDetectSwipe(containerRef, {
    onSwipe: (direction) => {
      if (direction === "left") {
        goToNextSlide()
      } else if (direction === "right") {
        goToPreviousSlide()
      }
    },
    thresholdPixel: 20
  })

  useEffect(()=>{
    document.body.style.overflow = "auto"
  },[])

  if (!slide)
    return <></>;

  const goToPreviousSlide = () => {
    setIndex(index => index - 1 < 0 ? 0 : index - 1);
  }


  return (
    <FullScreen
      handle={fullScreenHandler}
    >
      <div className="bg-[#000] h-screen" ref={containerRef as LegacyRef<HTMLDivElement>}>
        {
          slides.map((item: any, _index: number)=> {
            return(
              <div className={`h-screen w-screen flex flex-col justify-center items-center ${_index !== index ? "hidden":"" }`}>
                <ImageView 
                  url={item.thumbnail_url} 
                  className={`${info.os === "iPhone OS" || info.device === "iPad" ? "h-[90%] w-[90%]": ""}`}
                />
              </div>
            )
          })
        }
        <SlideNavigator
          handleFullScreen={fullScreenHandler}
          goToNextSlide={goToNextSlide}
          goToPreviousSlide={goToPreviousSlide}
          currentSlideNo={index + 1}
          totalSlideCount={slides.length}
        />
      </div>
    </FullScreen>
  );
};
